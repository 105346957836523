import { Icon } from '@iconify/react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

import Text from './Text';

export const alertStyles = cva('flex w-full flex-row gap-2 rounded-md p-3', {
  variants: {
    variant: {
      critical: 'bg-jb-alert-100 text-jb-alert-600 [&>svg]:text-jb-alert-600',
      info: 'bg-jb-neutral-200 text-jb-neutral-800 [&>svg]:text-jb-neutral-800',
      tip: 'bg-jb-neutral-200 text-jb-neutral-800 [&>svg]:text-jb-neutral-800',
    },
  },
  defaultVariants: {
    variant: 'info',
  },
});
type AlertStyles = VariantProps<typeof alertStyles>;

const icons: Record<NonNullable<AlertStyles['variant']>, string> = {
  critical: 'material-symbols:emergency-home-outline',
  info: 'material-symbols-light:info-outline',
  tip: 'material-symbols:emoji-objects-outline',
};

type Props = {
  className?: string;
} & (
  | { label: string; labelPosition?: 'left' | 'top' }
  | { label: never; labelPosition?: 'left' }
) &
  AlertStyles;
const Alert: FC<PropsWithChildren<Props>> = ({
  variant = 'info',
  children,
  className,
  labelPosition = 'left',
  label,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row',
        alertStyles({ variant }),
        className,
        {
          'flex-col': labelPosition === 'top',
        }
      )}
    >
      <div className="flex h-fit flex-row items-center gap-2">
        <Icon className="text-lg" icon={icons[variant ?? 'critical']} />
        {label && (
          <Text variant="jb-body-medium" className="font-semibold">
            {label}
          </Text>
        )}
      </div>
      <div className="flex flex-1">{children}</div>
    </div>
  );
};

export default Alert;
